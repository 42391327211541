$hover-background: #3e404e;
$light-background: #383A46;
$dark-background: #32323f;
$extra-dark-background: #2d2d38;
$light-text: #FEFDFF;
$dark-text: #707383;
$shadow: #141414;
$shadow-hover: #050505;
$accent-color: #5EB5F8;

$application-width: 1600px;

@media (min-width: 0px) {
  html { font-size: 60%; }
}

@media (min-width: 500px) {
  html { font-size: 70%; }
}

@media (min-width: 600px) {
  html { font-size: 80%; }
}

@media (min-width: 700px) {
  html { font-size: 90%; }
}

@media (min-width: 800px) {
  html { font-size: 100%; }
}
