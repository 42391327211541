@import '../../styles/variables';

.react-autosuggest__container {
  display: relative;
}

.react-autosuggest__input {
  width: 100%;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  width: 80%;
  background-color: rgba(0, 0, 0, 0.9);
  font-family: inherit;
  z-index: 2;
  margin-left: 5px;
  margin-top: -4px;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 10px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: $extra-dark-background;
}