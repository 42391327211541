@import "../../styles/variables";

.footer {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  a {
    font-size: 15px;
    margin: 5px;
    margin-left: 30px;
    margin-right: 30px;
    color: $dark-text;
  }
}