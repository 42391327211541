@import "../../styles/variables";
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@800&display=swap');

.landing {
  margin: 50px 15px;
  max-width: 100%;
  width: $application-width;

  .landing-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .landing-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .landing-subheader {
      font-family: 'Roboto Slab', serif;
      color: $accent-color;
      margin-top: 70px;
      font-size: 50px;
      max-width: 500px;
      text-align: center;
    }
    
    .landing-image {
      margin: 40px;
      max-width: 85%;
      width: 800px;
      box-shadow: rgba(0, 0, 0, 0.50) 0px 10px 36px 0px,
        rgba(0, 0, 0, 0.50) 0px 0px 0px 1px;
    }

    .landing-login {
      background-color: $accent-color;
      color: black;
      width: 250px;
      font-size: large;
      padding: 15px;
      margin-bottom: 30px;
    }

    .landing-login:hover {
      filter: brightness(80%);
    }

    .landing-paragraph {
      margin: 5px;
      max-width: 700px;
      text-align: center;
      line-height: 1.7;
    }

    .landing-sub-image {
      margin: 20px;
      max-width: 80%;
      width: 500px;
      box-shadow: rgba(0, 0, 0, 0.50) 0px 10px 36px 0px,
        rgba(0, 0, 0, 0.50) 0px 0px 0px 1px;
    }

    .landing-exchanges {
      font-family: 'Roboto Slab', serif;
      background-color: $light-background;
      padding: 10px;
      border-radius: 8px;
      margin: 5px;
    }

    .landing-footer {
      margin-top: 100px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      a {
        font-size: 15px;
        margin: 10px;
        margin-left: 30px;
        margin-right: 30px;
        color: $dark-text;
      }
    }
  }
}

.login-modalContent {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: $light-text;
  padding: 2%;
  width: 400px;
  max-width: 90%;
  margin: 0 auto;
  max-height: 80%;
  border-radius: 8px;
  outline: none;
  overflow: auto;
  background-color: $dark-background;
}

.login-modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(20, 20, 20, 0.8);
}
