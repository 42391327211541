@import "../../styles/variables";

.dashboard {
  margin: 50px 5%;
  max-width: 95%;
  width: $application-width;

  .dashboard-bottom {
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
  }
}

.dashboard-title {
  cursor: pointer;
}

.dashboard-footer {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  a {
    font-size: 15px;
    margin: 5px;
    margin-left: 30px;
    margin-right: 30px;
    color: $dark-text;
  }
}
