@import "./variables";

button {
  font-family: inherit;
  padding: 0.5em 0.5em;
  margin: 5px;
  background-color: $light-background;
  border: 2px solid $light-background;
  color: $light-text;
  border-radius: 8px;
  cursor: pointer;
}

button:hover {
  background-color: $hover-background;
}

input, select {
  font-family: inherit;
  font-size: 15px;
  padding: 0.5em 0.5em;
  margin: 5px;
  background-color: $light-background;
  border: 2px solid $light-background;
  color: $light-text;
  border-radius: 8px;
  height: 40px;
  -webkit-appearance: none;
}

select:hover {
  cursor: pointer;
  background-color: $hover-background;
}

input:placeholder-shown {
  color:$dark-text;
  -webkit-text-fill-color: $dark-text;
  opacity: 1;
}

input:disabled { 
  color:$dark-text;
  -webkit-text-fill-color: $dark-text;
  opacity: 1;
}

label {
  font-family: inherit;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 1.2em;
  margin: 0;
  margin-bottom: 20px;
  margin-left: 5px;
}

h2 {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  font-weight: bold;
  line-height: 43px;
  margin: 0;
  margin-left: 5px;
}

h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  margin: 0;
  margin-left: 5px;
}

h4 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 125%;
  margin: 0;
  margin-left: 5px;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  margin-left: 5px;
  margin: 0
}

table {
  width: 100%;
  table-layout: fixed;
  text-align: left;
  background-color: $light-background;
  border-radius: 8px;
  border-collapse: collapse;

  .one {
    width: 10%;
  }

  .two {
    width: 15%;
  }

  .three {
    width: 20%;
  }

  .four {
    width: 25%;
  }

  th {
    color: $accent-color;
    cursor: pointer;
    padding: 5px;
    padding-left: 1%;
    
    th:first-child {
      border-top-left-radius: 8px;
    }
    th:last-child {
      border-top-right-radius: 8px;
    }
  }

}

.cover {
  z-index: 9998;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
}

.spinner {
  z-index: 9999;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-left: -30px;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  border-style: solid;
  border-color: white;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin 1.3s linear infinite;
  animation: spin 1.3s linear infinite;
}

.logo {
  z-index: 9999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
