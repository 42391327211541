@import "./variables";
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@700&display=swap");
@import url('https://rsms.me/inter/inter.css');

* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  margin: 0;
  margin: 0;
  padding: 0;
  font-family: 'Inter var', sans-serif;;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  background-color: $extra-dark-background;
  color: $light-text;
  box-sizing: border-box;
  height: 100vh;
  min-height: 100%;
}

#root {
  height: 100%;
}

.app {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
