@import '../../styles/variables';

.total-networth {
}

.total-change {
  margin-bottom: 10px;
  margin-left: 10px;
}

.total-subtitle {
  color: $dark-text;
}