@import "../../styles/variables";

.asset-row:hover {
  background-color: $hover-background;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.6s;
}
.asset-row {
  cursor: pointer;
  transition: 0.3s;
}

.asset-row > td {
  padding: 8px;
  padding-left: 1%;
}

.asset-row:last-child:hover td:first-child {
  border-bottom-left-radius: 8px;
}
.asset-row:last-child:hover td:last-child {
  border-bottom-right-radius: 8px;
}