@import '../../styles/variables';

.addAsset-modalContent {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: $light-text;
  padding: 2%;
  width: 600px;
  max-width: 90%;
  margin: 0 auto;
  max-height: 80%;
  border-radius: 8px;
  outline: none;
  overflow: auto;
  background-color: $dark-background;

  .addAsset-form {
    display: flex;
    flex-direction: column;

    .addAsset-label {
      margin: 5px;
    }

    .addAsset-price {
      margin: 5px;
      margin-left: 10px;
      margin-bottom: 20px;
      color: $dark-text;
    }

    .addAsset-button {
      font-size: 16px;
      margin-top: 20px;
      color: $light-text;
    }
  }
}

.addAsset-modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(20, 20, 20, 0.8);
}
