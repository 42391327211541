@import '../../styles/variables';

.portfolio {
  background-color: $dark-background;
  border-radius: 8px;
  margin-left: 5px;
  padding: 2%;

  .group {
    margin-bottom: 30px;
  }

  .group:last-child {
    margin-bottom: 0px;
  }
}